<template>
  <div class="pageem relative">
    <!-- 引入的返回头组件 -->
    <!-- <titlebar :name="name" /> -->
    <div class="search_box">
      <input
        type="text"
        class="search"
        v-model="number"
        placeholder="请输入员工工号"
      />
      <input
        type="text"
        class="search"
        v-model="uname"
        placeholder="请输入员工姓名"
      />
      <div class="search_btn" @click="search()">搜索</div>
      <button class="library" @click="AddTo" v-if="shop.user_model == 1 && user_type == 1">
        <van-icon name="add" size="2.6rem" class="mg-rt10" color="#fff" />
        <span>添加员工</span>
      </button>
      <!-- <div class="library" @click="goTrust()" v-if="shop.user_model == 1">交班记录</div> -->
    </div>
    <div class="List_box bg-F2F2F2">
      <div class="list-td ft-bold">员工姓名</div>
      <div class="list-td ft-bold">工号</div>
      <div class="list-td ft-bold">员工账号</div>
      <div class="list-td ft-bold">手机号码</div>
      <div class="list-td ft-bold">角色类型</div>
      <div class="list-td ft-bold">账号状态</div>
      <!-- <div class="list_people">创建时间</div> -->
      <div class="list-td ft-bold">操作</div>
    </div>
    <div class="list_page">
      <div class="List_box" v-for="(item, index) in staff_list" :key="index">
        <!-- <div class="List_click" @click="Detail"> -->
        <div class="list-td">{{ item.staff_name }}</div>
        <div class="list-td">{{ item.staff_num }}</div>
        <div class="list-td">{{ item.staff_account }}</div>
        <div class="list-td">{{ item.staff_mobile }}</div>
        <div class="list-td">{{ item.role_name }}</div>
        <div class="list-td" v-if="item.status == 1">正常</div>
        <div class="list-td" v-if="item.status == 2">禁用</div>
        <!-- <div class="list_people">{{ item.create_time }}</div> -->
        <!-- </div> -->
        <div class="list-td flex flex-ac flex-jc nowrap">
          <template v-if="shop.user_model == 1 && user_type == 1">
            <van-icon name="edit" size="3rem" @click="Edit(item)" />
            <van-icon class="mg-lt35" name="delete-o" size="3rem" color="#ee0a24" @click="openDialog(item.id)" v-if="item.type != 1" />
          </template>
          <template v-else>无权操作</template>
        </div>
      </div>
    </div>
    <div class="pagination">
      <van-pagination
        v-model="page"
        :total-items="total"
        :items-per-page="limit"
        @change="pageChange"
        force-ellipses
        class="pagination-foot"
        style="background-color: #f2f2f2"
      />
    </div>
    <!-- 编辑 -->
    <van-popup v-model="showed">
      <div class="popups">
        <div class="system_title">
          <div>编辑员工</div>
          <img
            src="https://dzb.api.casher.kissneck.net/202106/mdsy/close.png"
            @click="closeMenu"
          />
        </div>
        <!-- 员工信息 -->
        <div class="detail">
          <!-- <div @click="openrole()">
            角色类型：<input
              type="text"
              v-model="operatevl"
              disabled
              :placeholder="operatevl"
            />
          </div> -->
          <div>员工姓名：<input type="text" v-model="staff_name" /></div>
          <div>员工账号：<input type="text" v-model="staff_account" /></div>
          <div>账号密码：<input type="text" v-model="staff_paw" placeholder="不输入，则不修改密码"/></div>
          <div>手机号码：<input type="text" v-model="staff_mobile" /></div>
        </div>
        <div class="but" @click="EditEmployees">确定</div>
      </div>
    </van-popup>
    <!-- 添加员工 -->
    <van-popup v-model="showdet">
      <div class="popups">
        <div class="system_title">
          <div>员工详情</div>
          <img
            src="https://dzb.api.casher.kissneck.net/202106/mdsy/close.png"
            @click="closeDet"
          />
        </div>
        <!-- 员工信息 -->
        <div class="detail">
          <!-- <div @click="openrole()">
            角色类型：<input
              type="text"
              v-model="operatevl"
              disabled
              placeholder="请选择角色类型"
            />
          </div> -->
          <div>员工姓名：<input type="text" v-model="staff_name" /></div>
          <div>员工账号：<input type="text" v-model="staff_account" /></div>
          <div>账号密码：<input type="text" v-model="staff_paw" pattern="如不修改"/></div>
          <div>手机号码：<input type="text" v-model="staff_mobile" /></div>
        </div>
        <div class="but" @click="AddEmployees">确定</div>
      </div>
    </van-popup>
    <!-- 角色类型： -->
    <van-popup v-model="showcz" position="bottom" :style="{ height: '40%' }">
      <van-picker
        title="角色类型："
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
        @change="onChange"
        value-key="role_name"
      />
    </van-popup>

    <popupOfDialog
      v-if="showDialog"
      :type="dialogType"
      :title="dialogTitle"
      :message="dialogMsg"
      :data="dialogData"
      @closePopDialog="closePopDialog"
      @confirmPopDialog="confirmPopDialog"
    ></popupOfDialog>
  </div>
</template>

<script>
import { Toast } from "vant";
import { Dialog } from "vant";
import titlebar from "@/views/component/titlebar";
import { mapGetters } from 'vuex';
import popupOfDialog from "../component/popupOfDialog.vue";
export default {
  created() {
    this.name = this.$route.query.name; //拿到传过来的标题
    console.log("name:", this.name);
    this.getStafListf(); //获取员工列表
  },
  components: {
    titlebar: titlebar,
    popupOfDialog,
  },
  data() {
    return {
      page: 1,
      limit: 8,
      total: null,
      showcz: false, // 角色类型：
      // operatevl: "", //角色类型的名字
      operatevl_id: "", //角色类型的ID
      staff_name: "", //员工名称
      staff_account: "", //员工账号
      staff_paw: undefined, //员工密码
      staff_mobile: "", //员工手机号码
      columns: [], //角色类型：
      staff_list: [], //员工列表
      number: undefined, // 搜索员工工号
      uname: undefined, // 搜索员工姓名
      showed: false, // 编辑弹窗
      showdet: false, // 添加弹窗
      staff_id: '', // 员工id
      showDialog: false,
      dialogTitle: "",
      dialogMsg: "",
      dialogType: "",
      dialogData: {},
    };
  },
  computed: {
    ...mapGetters({shop: 'shop'}),
    user_type() {
      return localStorage.user_type;
    }
  },
  methods: {
    // 搜索
    search() {
      // this.number != '' ? this.number : undefined;
      // this.uname != '' ? this.uname : undefined;
      if(this.number == '') {
        this.number = undefined
      }
      if(this.uname == '') {
        this.uname = undefined
      }
      let data = {
        staff_num: this.number,
        staff_name: this.uname,
        shop_id: localStorage.getItem("shop_id"),
        limit: this.limit,
        page: this.page,
      };
      this.$api.stafListf(data).then((res) => {
        this.staff_list = res.data.list;
        this.total = res.data.count;
      });
    },
    // 选择角色类型弹窗
    openrole() {
      this.showcz = true;
    },
    onChange(picker, value, index) {
      // Toast(`picker:${picker}, 当前值：${value}, 当前索引：${index}`);
    },
    // 角色类型确认
    onConfirm(e) {
      console.log("角色类型===", e);
      this.operatevl = e.role_name;
      this.operatevl_id = e.role_id;
      this.showcz = false;
    },
    // 角色类型取消
    onCancel() {
      n;
      this.showcz = false;
    },
    //员工管理 添加/编辑/删除员工
    setStaffAction(data) {
      data.shop_id = localStorage.getItem("shop_id");
      this.$api.staffAction(data).then((res) => {
        if(res.code == 1) {
          this.getStafListf();
        }
      });
    },
    // 获取员工列表
    getStafListf() {
      var data = {
        shop_id: localStorage.getItem("shop_id"),
        limit: this.limit,
        page: this.page,
      };
      this.$api.stafListf(data).then((res) => {
        this.staff_list = res.data.list;
        this.total = res.data.count;
        // this.columns = res.data.list[2].role;
        // console.log("columns", this.columns);
      });
    },
    // 编辑弹窗开启
    Edit(item) {
      // console.log("编辑员工:", item);
      // this.showed = true;
      // this.operatevl = item.operatevl;
      // this.staff_id = item.id;
      // // this.operatevl_id = item.operatevl_id;
      // this.staff_name = item.staff_name;
      // this.staff_account = item.staff_account;
      // // this.staff_paw = item.staff_paw;
      // this.staff_mobile = item.staff_mobile;
      // console.log(
      //   "编辑员工名称:",
      //   this.staff_name,
      //   "角色类型id:",
      //   this.operatevl_id,
      //   "111",
      //   "员工账号:",
      //   this.staff_account,
      //   this.staff_paw,
      //   this.staff_mobile
      // );
      this.$router.push({
        name: "addWorker",
        query: { 
          name: "editWorker",
          active: 3,
          id: item.id
        },
        params: {
          workerinfo: item,
        }
      });
    },
    // 关闭编辑弹窗
    closeMenu() {
      this.showed = false;
    },
    // 打开添加员工弹窗
    AddTo() {
      this.$router.push({
        path: "/addWorker",
        query: { 
          name: "addWorker",
          active: 3,
        },
      });
      // this.showdet = true;
      // this.operatevl = "";
      // // this.operatevl_id = "";
      // this.staff_name = "";
      // this.staff_account = "";
      // this.staff_paw = undefined;
      // this.staff_mobile = "";
    },
    // 添加员工
    AddEmployees() {
      console.log(
        "员工名:",
        this.staff_name,
        "员工账号:",
        this.staff_account,
        this.staff_paw,
        this.staff_mobile
      );
      if (
        this.staff_name &&
        this.staff_account &&
        this.staff_mobile
      ) {
        let data = {
          // role_id: this.operatevl_id,
          staff_name: this.staff_name,
          staff_account: this.staff_account,
          staff_paw: this.staff_paw,
          staff_mobile: this.staff_mobile,
          t: 1, //1增加2编辑3删除
          status: 1,
        };
        this.setStaffAction(data);
        this.showdet = false;
      } else {
        Toast(`请确认填写的信息是否完整`);
      }
    },
    // 编辑员工
    EditEmployees() {
      console.log(
        "编辑员工名称:",
        this.staff_name,
        "角色类型id:",
        this.operatevl_id,
        "111",
        "员工账号:",
        this.staff_account,
        this.staff_paw,
        this.staff_mobile
      );
      if (
        this.staff_name &&
        this.staff_account &&this.staff_mobile
      ) {
        let data = {
          // role_id: this.operatevl_id,
          staff_name: this.staff_name,
          staff_account: this.staff_account,
          staff_paw: this.staff_paw,
          staff_mobile: this.staff_mobile,
          staff_id: this.staff_id,
          t: 2, //1增加2编辑3删除
          status: 1,
        };
        this.setStaffAction(data);
        this.showed = false;
      } else {
        Toast(`请确认填写的信息是否完整`);
      }
    },
    // 关闭详情弹窗
    closeDet() {
      this.showdet = false;
    },
    confirmPopDialog(data) {
      this.Delete(data.data.id);
      this.closePopDialog();
    },
    closePopDialog(data) {
      this.showDialog = false;
    },
    openDialog(id) {
      this.dialogTitle = "提示";
      this.dialogMsg = `是否确认删除？`;
      this.dialogType = '4';
      this.dialogData = { id: id };
      this.showDialog = true;
    },
    // 删除员工
    Delete(e) {
      let data = {
        staff_id: e,
        t: 3, //1增加2编辑3删除
      };
      console.log("打印删除1111===", e);
      this.setStaffAction(data);
      Toast(`删除成功`);
      this.getStafListf();
    },
    // 跳转交接班
    goTrust() {
      this.$router.push({
        path: "/progress",
        query: { 
          name: "progress",
          active: 3,
        },
      });
    },
    // 页码改变
    pageChange(e) {
      this.page = e;
      console.log("销售商品列表，当前页码：", this.page);
      this.getStafListf();
    },
  },
};
</script>

<style lang="less" scoped>
.pagination {
  position: absolute;
  bottom: 3rem;
  right: 0;
  width: 30%;
}
/deep/.van-pagination__item--active {
  color: #fff !important;
  // background-color: #1588F5 !important;
}
// /deep/.van-pagination__item {
//   color: #1588F5;
// }
.pageem {
  // width: 192rem;
  height: 100%;
  background: #fff;
  overflow: hidden;
  // align-items: center;
  .search_box {
    margin: 2.5rem 3rem 2rem;
    display: flex;
    align-items: center;
    .search {
      width: 22rem;
      height: 6.5rem;
      background: #f8f8f8;
      border-radius: 8px;
      font-size: 2.6rem;
      font-weight: 400;
      color: #666666;
      // text-align: center;
      line-height: 3.7rem;
      margin-right: 2.7rem;
      padding-left: 2rem;
    }
    .choose {
      width: 23.4rem;
      height: 8rem;
      display: flex;
      align-items: center;
      color: #666666;
      background: #f8f8f8;
      font-size: 2.6rem;
      border-radius: 0.8rem;
      padding-left: 4.1rem;
      position: relative;

      img {
        width: 2.2rem;
        height: 1.4rem;
        position: absolute;
        right: 2.8rem;
      }
    }
    .search_btn {
      width: 12rem;
      height: 6rem;
      background: #1588F5;
      font-size: 2.6rem;
      font-weight: 400;
      color: #ffffff;
      line-height: 3.7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      // margin-left: 3rem;
      margin-right: auto;
      border-radius: 0.8rem;
    }
    .library {
      background: #1588F5;
      color: #fff;
      border-radius: 0.8rem;
      padding: 0 2rem;
      height: 6rem;
      font-size: 2.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .list_page {
    // height: 67rem;
    height: calc(100% - 32rem);
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .List_box {
    margin: 0 3rem 0;
    // margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 192rem;
    height: 9rem;
    font-size: 2.6rem;
    font-weight: bold;
    color: #333333;
    border-bottom: 0.1rem solid #e5e5e5;

    .list-td {
      flex: 1;
      height: 9rem;
      line-height: 9rem;
      text-align: center;
    }
  }
  .popups {
    width: 102.9rem;
    height: 82.7rem;
    background: #ffffff;
    text-align: -webkit-center;

    .system_title {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0 4.6rem 3.1rem 4.6rem;
      height: 12.5rem;
      border-bottom: 0.1rem solid #e5e5e5;
      font-size: 3.6rem;
      font-weight: 400;
      color: #333333;
      img {
        width: 4.3rem;
        height: 4.3rem;
        // text-align: end;
        margin-left: 29.4rem;
      }
    }

    .detail {
      margin: 4rem 17.6rem 8.5rem 18rem;
      text-align: left;
      font-size: 2.8rem;
      font-weight: bold;
      color: #333333;
      line-height: 4rem;

      input {
        width: 43.6rem;
        height: 7.1rem;
        background: #f8f8f8;
        border-radius: 0.8rem;
        margin: 0 0 2rem 2rem;
        padding-left: 6.3rem;
        font-size: 2.6rem;
        font-weight: 400;
        color: #666666;
        line-height: 3.7rem;
      }
    }

    .but {
      width: 27rem;
      height: 7rem;
      background: linear-gradient(270deg, #1588F5 0%, #1588F5 100%);
      font-size: 2.8rem;
      font-weight: 500;
      color: #ffffff;
      line-height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  /deep/.van-picker__cancel {
    font-size: 2.6rem !important;
  }
  /deep/.van-picker__title {
    font-size: 2.6rem !important;
    height: 100%;
    display: flex;
    align-items: center;
  }
  /deep/.van-picker__confirm {
    font-size: 2.6rem !important;
  }
  /deep/.van-ellipsis {
    font-size: 2.6rem !important;
  }
  .bg_f8 {
    background: #f8f8f8;
  }
}
</style>
